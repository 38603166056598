<template>
    <div class="analytics">
        <title-bar
            :title="$t('forms.analytics')"
        />
        <b-card no-body>
            <b-tabs v-model="tabIndex" card content-class="mt-3">
                <b-tab :title="$t('forms.currentData')" active>
                    <current-data/>
                </b-tab>
                <b-tab :title="$t('forms.neverUsedItems')">
                    <never-used-items/>
                </b-tab>
                <b-tab :title="$t('forms.crossSaleUsage')">
                    <cross-sale/>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import CurrentData from "./components/CurrentData.vue"
import NeverUsedItems from "./components/NeverUsedItems.vue"
import CrossSale from "./components/CrossSale.vue"

export default {
    components: {
        TitleBar,
        CurrentData,
        NeverUsedItems,
        CrossSale
    },
    data() {
        return {
            tabIndex: 0
        }
    }
}
</script>
