<template>
    <div>
        <advanced-filter
            :fields-array="filterFields"
            :action="showFilterModal"
            @clearAction="showFilterModal = false"
            @paramsChange="setAdvancedParams"
        />
        <div v-if="rows" class="custom-table">
            <table-actions
                :actions="['search', 'perPage', 'advancedFilter']"
                @onSearchInput="inputChange"
                @perPageChange="perPageChange"
                @filterChange="showFilterModal = true"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
            >
                <template v-slot:createdAt="data">
                    {{ $helper.formatDate(data.value.createdAt) }}
                    {{ $helper.formatTime(data.value.createdAt) }}
                </template>
                <template v-slot:partNumber="data">
                    {{ data.value.stockItem.partNumber }}
                </template>
                <template v-slot:quantity="data">
                    {{ data.value.quantity }}
                </template>
                <template v-slot:station="data">
                    {{ data.value.stockItem.station }}
                </template>
                <template v-slot:price="data">
                    {{ data.value.stockItem.price }}
                </template>

                <template v-slot:owner="data">
                    {{ data.value.stockItem.owner.name }}
                </template>

                <template v-slot:type="data">
                    <b-badge variant="primary">{{ data.value.type.replace('_', ' ') }}</b-badge>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>
    </div>
</template>

<script>
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import AdvancedFilter from "@/components/Table/AdvancedFilter.vue";

export default {
    name: "AdminCrossSaleListing",
    components: {
        TableLite,
        TablePagination,
        TableActions,
        AdvancedFilter
    },
    mixins: [ResourceUtils],
    data() {
        return {
            search: "",
            isLoading: false,
            rows: null,
            perPage: this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            showFilterModal: false,
            sortable: {
                order: "createdAt",
                sort: "asc",
            },
            filterFields: [
                {
                    label: 'forms.created',
                    name: "createdAt",
                    value: null,
                    type: "range"
                },
            ],
            advancedParams: [],
            columns: [
                {
                    label: this.$t("forms.createdAt"),
                    field: "createdAt",
                    sortable: true,
                },
                {
                    label: this.$t("forms.partNumber"),
                    field: "partNumber",
                    sortable: true,
                },
                {
                    label: this.$t("forms.quantity"),
                    field: "quantity",
                    sortable: true,
                },
                {
                    label: this.$t("forms.station"),
                    field: "station",
                    sortable: true,
                },
                {
                    label: this.$t("forms.price"),
                    field: "price",
                    sortable: true,
                },
                {
                    label: this.$t("forms.owner"),
                    field: "owner",
                    sortable: false,
                },
                {
                    label: this.$t("forms.usage"),
                    field: "type",
                    sortable: true,
                },
            ],
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
            };

            params["order[" + this.sortable.order + "]"] = this.sortable.sort
            params["type"] = "SOLD"
            params["quantity[gt]"] = 0
            params["stockItem.owner"] = 4 //TODO use env variables

            if (this.search && this.search.length) {
                params.search = this.search;
            }
            this.advancedParams.forEach(element => {
                if (element.value !== '' && element.value !== null) {
                    params[element.name] = element.value.toISOString().split('T')[0]
                }
            });
            this.$UsedItems.getCollection({params}, 'cross_sale_items').then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
        setSort(offset, limit, order, sort) {
            this.sortable.sort = sort;
            this.sortable.order = order;
            if (order === 'price' || order === 'partNumber') {
                this.sortable.order = 'stockItem.' + order;
            }
            this.load();
        },
        onChangePage(page) {
            this.currentPage = page;
            this.load();
        },
        deleteUsedItem(url) {
            this.deleteByUrl(this.$UsedItems, url, this.$t("messages.usedItemDeleted"), null, this.load, this.error);
        },
        inputChange(value) {
            this.currentPage = 1;
            this.search = value;
            this.load();
        },
        onPageChange() {
            this.load();
        },
        perPageChange(num) {
            this.currentPage = 1;
            this.perPage = num;
            this.load();
        },
        error() {
            this.$emit("clearAction");
        },
        setAdvancedParams(data) {
            this.advancedParams = data
            this.load()
        }
    },
};
</script>
